import React from "react";
import { Helmet } from "react-helmet";
import InternalLink from "../InternalLink";
import { Title, Subtitle } from "../../sdk/Layout";
import { Button } from "../../sdk/Button";
import { Slide, LeftSide, RightSide, Login } from "./style";

function SliderD({ config }) {
    if (config == null || config.length === 0) return null;
    const img = config[0].img ? config[0].img.img[0] : "";
    const bgimg = config[0].imgmobile ? config[0].imgmobile.img[0] + '?d=400x400' : "";
    const srcSet = ` 
                        ${img}?d=300x300 300w,
                        ${img}?d=400x400 400w,
                        ${img}?d=600x600 600w,
                        ${img}?d=700x700 700w,
                        ${img}?d=900x900 900w,
                        ${img}?d=1100x1100 1100w
                `;
    return (
        <Slide>
            <Helmet>
                <link
                    rel="preload"
                    as="image"
                    href={img}
                    imagesrcset={srcSet}
                    imagesizes="50vw"
                ></link>
            </Helmet>
            <LeftSide bgimg={bgimg}>
                <div className="container">
                    <Title>{config[0].title}</Title>
                    <Subtitle as="h2">{config[0].title2}</Subtitle>
                    
                    <div className="buttons">
                        <InternalLink {...config[0].link1}>
                            <Button>
                                <span>{config[0].cta1}</span>{" "}
                            </Button>
                        </InternalLink>
                        {/* {config[0].cta2 !== "" ? (
                            <InternalLink {...config[0].link2}>
                                <Login>{config[0].cta2}</Login>
                            </InternalLink>
                        ) : null} */}
                    </div>
                </div>
            </LeftSide>
            <RightSide>
                <img src={img} srcSet={srcSet} alt={config[0].altimg} />
            </RightSide>
        </Slide>
    );
}

export default SliderD;
