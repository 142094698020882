import styled from "styled-components";
import { Title, Subtitle } from "../../sdk/Layout";
import { Button } from "../../sdk/Button";

export const Slide = styled.div`
    display: flex;
    position: relative;
    min-height: 560px;
    @media (max-width: 768px) {
        text-align: center;
        min-height: 300px;
    }
`;

export const LeftSide = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 80px;
  border-right: solid 5px white;
  background-color: ${(props) => props.theme.primary};
  background-size: cover;
  background-position: center;

  .container {
    max-width: 550px;
    padding: 20px 0;
  }
    ${Title} {
        margin-bottom: 0;
        padding: 0;
        font-family: futura;
        color: white;
        font-size: 30px;
        text-transform: uppercase;
    }
    ${Subtitle} {
        font-family: helvetica;
        color: white;
        font-size: 19px;
        text-transform: inherit;
        margin-top: 10px;
    }
    ${Button} {
        margin-top: 40px;
        font-size: 18px;
        font-weight: bold;
        border: none;
        display: inline-block;
        @media (max-width: 768px) {
            margin-top: 20px;
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        background-image: url(${(props) => props.bgimg});
        box-shadow: ${(props) => props.bgimg && 'inset 0 -2000px 0 rgba(0,0,0,.5)'};
        padding: 10px;
        ${Title} {
            font-size: 24px;
        }
        ${Subtitle} {
            font-size: 16px;
        }
        ${Button} {
            font-size: 16px;
        }
    }
`;

export const RightSide = styled.div`
  width: 50%;
  position: relative;
    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media (max-width: 768px) {
        display: none;
    }
`;


export const Login = styled.span`
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
    color: white;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
`;
